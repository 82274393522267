import React from 'react';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {Box, Button} from '@mui/material';
import {SearchPartyFlag} from '@app/components/search-bar-party-flag';
import {masterManagementStateActions} from '@app/screens/master-data-management/redux/slice';
import {appSelector} from '@app/store/selectors';
import {trackEvent} from '@app/utils/analytics';
import {EVENT_NAME_ADD_NEW_BUTTON} from '@app/utils/analytics/constants';

const styleSheet = () => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    flexSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    button: {
      borderRadius: '5px',
      width: '150px',
      fontStyle: 'normal',
      textAlign: 'center',
      fontWeight: 'normal',
      fontSize: 12.7,
    },
  };
};
export const PartFlagNavbar = () => {
  const styles = styleSheet();
  const dispatch = useDispatch();

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
    shallowEqual,
  );

  const manageAddNewPopup = () => {
    dispatch(
      masterManagementStateActions.setPartyFlagKeyAndValue({
        key: 'addNew',
        value: true,
      }),
    );
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography variant="h6" noWrap component="div">
          {navbarComponentHeading}
        </Typography>

        <Button
          //   disabled={!isEditAccessAvailable}
          variant="contained"
          data-testid="add-new"
          sx={{
            ...styles.button,
            //     ...(!isEditAccessAvailable && {color: 'grey'}),
          }}
          onClick={manageAddNewPopup}>
          Add New
        </Button>
      </Box>
      <SearchPartyFlag />
    </Box>
  );
};
