import {createAction, createSlice} from '@reduxjs/toolkit';

export const masterManagementInitialState = {
  masterPartyType: [],
  masterPartyTypeLoading: false,
  groupDropdownMasterPartyTypeData: [],
  masterPartyCategoryData: [],
  masterPartCategoryLoading: false,
  partyCategoryIsActiveState: true,
  partyTypeIsActiveState: true,
  partyCategorySearchText: '',
  partyTypeSearchText: '',
  partyCategoryKeyAndValue: {},
  partyTypeKeyAndValue: {},
  editPartyCategoryData: {},
  editPartyTypeData: {},
  partyCategoryColorCode: [],
  partyCategorySaveError: false,
  savePartyCategoryData: {},
  partyFlagKeyAndValue: {},
  editPartyFlagData: {},
  partyFlagColorCode: [],
  partyGroupKeyAndValue: {},
  divisionGroupKeyAndValue: {},
  customPropertyKeyAndValue: {},
  editCustomPropertyData: {},
  partyCategoryPage: 0,
  partyCategoryPageSize: 10,
  partyTypeMasterPage: 0,
  partyTypeMasterPageSize: 10,
  PartyFlagDetailsLoading: false,
  partyFlagDetails: [],
  addEditPartyDetails: {},
  updatepartyFlagData: [],
  partyFlagPage: 0,
  partyFlagPageSize: 10,
  partyFlagIsActiveState: true,
  partyFlagSearchText: '',
  totalCount: 0,
  partyFlagError:false,
  partyFlagSavedData:{}
};

export const fetchMasterPartyTypeCreator = createAction<any>(
  'MASTER_MANAGEMENT_HANDLER/FETCH_MASTER_PARTY_TYPE',
);
export const fetchMasterPartyTypeCreatorTypeName =
  fetchMasterPartyTypeCreator(null).type;

export const fetchMasterPartyCategoryCreator = createAction<any>(
  'MASTER_MANAGEMENT_HANDLER/FETCH_MASTER_PARTY_CATEGORY',
);
export const fetchMasterPartyCategoryCreatorTypeName =
  fetchMasterPartyCategoryCreator(null).type;

export const groupDropdownMasterPartyTypeCreator = createAction(
  'MASTER_MANAGEMENT_HANDLER/FETCH_GROUP_DROPDOWN_MASTER_PARTY_TYPE',
);

export const groupDropdownMasterPartyTypeCreatorTypeName =
  groupDropdownMasterPartyTypeCreator().type;

export const addEditDeletePartyCategoryCreator = createAction<any>(
  'MASTER_MANAGEMENT_HANDLER/ADD_EDIT_DELETE_PARTY_CATEGORY',
);

export const addEditDeletePartyCategoryCreatorName =
  addEditDeletePartyCategoryCreator({}).type;

export const saveMasterPartyTypeCreator = createAction<any>(
  'MASTER_MANAGEMENT_HANDLER/ADD_EDIT_DELETE_PARTY_TYPE',
);

export const saveMasterPartyTypeCreatorTypeName = saveMasterPartyTypeCreator(
  {},
).type;
export const fetchPartyFlagdetailsCreator = createAction<any>(
  'FETCH_MASTER_PARTY_FLAG_DETAILS',
);
export const fetchPartyFlagdetailsCreatorTypeName =
  fetchPartyFlagdetailsCreator(null).type;

export const addEditPartyFlagDetailsCreator = createAction<any>(
  'FETCH_MASTER_PARTY_FLAG_ADD/EDIT_DETAILS',
);
export const addEditPartyFlagDetailsCreatorTypeName =
  addEditPartyFlagDetailsCreator(null).type;
export const UpdatepartyFlagDataCreator = createAction<any>(
  'FETCH_MASTER_PARTY_FLAG_UPDATE_DETAILS',
);
export const UpdatepartyFlagDataCreatorTypeName =
  UpdatepartyFlagDataCreator(null).type;
const masterManagementStateHandler = createSlice({
  name: 'MASTER_MANAGEMENT_HANDLER',
  initialState: masterManagementInitialState,
  reducers: {
    // Party Type
    setMasterPartyType: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        masterPartyType: payload,
      };
    },

    setMasterPartyTypeLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        masterPartyTypeLoading: payload,
      };
    },
    setGroupDropdownMasterPartyTypeData: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        groupDropdownMasterPartyTypeData: payload,
      };
    },
    setPartyTypeKeyAndValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyTypeKeyAndValue: payload,
      };
    },
    getEditPartyTypeData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editPartyTypeData: payload,
      };
    },
    setPartyTypeSearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyTypeSearchText: payload,
      };
    },
    setPartyTypeIsActiveState: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyTypeIsActiveState: payload,
      };
    },

    setPartyTypeMasterPage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyTypeMasterPage: payload,
      };
    },

    setPartyTypeMasterPageSize: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyTypeMasterPageSize: payload,
      };
    },

    // Party Category
    setMasterPartyCategory: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        masterPartyCategoryData: payload,
      };
    },
    setMasterPartyCategoryLoding: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        masterPartCategoryLoading: payload,
      };
    },

    setPartyCategoryIsActiveState: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyCategoryIsActiveState: payload,
      };
    },
    setPartyCategorySearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyCategorySearchText: payload,
      };
    },
    setPartyCategoryKeyAndValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyCategoryKeyAndValue: payload,
      };
    },
    setEditPartyCategoryData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editPartyCategoryData: payload,
      };
    },
    setPartyCategoryColorCode: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyCategoryColorCode: payload,
      };
    },
    setPartyCategoryPage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyCategoryPage: payload,
      };
    },
    setPartyCategoryPageSize: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyCategoryPageSize: payload,
      };
    },
    setMasterPartyCategorySaveError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyCategorySaveError: payload,
      };
    },
    setSavePartyCategoryData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        savePartyCategoryData: payload,
      };
    },

    // Party Flag

    setPartyFlagKeyAndValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyFlagKeyAndValue: payload,
      };
    },
    setEditPartyFlagData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editPartyFlagData: payload,
      };
    },
    setPartyFlagIsActiveState: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyFlagIsActiveState: payload,
      };
    },
    setUpdatepartyFlagData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        updatepartyFlagData: payload,
      };
    },
    setAddEditpartyFlagDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addEditPartyDetails: payload,
      };
    },
    setpartyFlagDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyFlagDetails: payload,
      };
    },
    setpartyFlagDetailsLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        PartyFlagDetailsLoading: payload,
      };
    },

    setPartyFlagPage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyFlagPage: payload,
      };
    },
    setPartyFlagPageSize: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyFlagPageSize: payload,
      };
    },
    setPartyFlagSearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyFlagSearchText: payload,
      };
    },
    setPartyFlagColorCode: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyFlagColorCode: payload,
      };
    },

    setTotalCount: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        totalCount: payload,
      };
    },
    setPartyFlagError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyFlagError: payload,
      };
    },
    setPartyFlagSavedData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyFlagSavedData: payload,
      };
    },
    
    

    // Party Type Group
    setPartyGroupKeyAndValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyGroupKeyAndValue: payload,
      };
    },

    // Division Group
    setDivisionGroupKeyAndValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        divisionGroupKeyAndValue: payload,
      };
    },

    // custom Property
    setcustomPropertyKeyAndValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        customPropertyKeyAndValue: payload,
      };
    },
    setEditCustomPropertyData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editCustomPropertyData: payload,
      };
    },
  },
});

export const masterManagementStateActions =
  masterManagementStateHandler.actions;
export const masterManagementStateReducer =
  masterManagementStateHandler.reducer;
