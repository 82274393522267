import {createAction, createSlice} from '@reduxjs/toolkit';

export const inventoryManagementInitialState = {
  divisionGRP: [],
  divisionGRPLoading: false,
  division: [],
  partyGRP: [],
  partyData: [],
  partyCategoryData: [],
  isVisibleKeyAndValue: {},
  editPartyDivisionData: {},
  partyDivisionMappingData: [],
  oneDataOfPartyDivision: {},
  oneDataOfPartyTypeData: {},
};

export const fetchInventoryDivisionGroupCreator = createAction<any>(
  'INVENTORY_MANAGEMENT_HANDLER/FETCH_DIVISION_GROUP',
);
export const fetchInventoryDivisionGroupCreatorTypeName =
  fetchInventoryDivisionGroupCreator(null).type;

export const fetchInventoryDivisionCreator = createAction<any>(
  'INVENTORY_MANAGEMENT_HANDLER/FETCH_DIVISION',
);
export const fetchInventoryDivisionCreatorTypeName =
  fetchInventoryDivisionCreator(null).type;

export const fetchInventoryPartyGroupCreator = createAction<any>(
  'INVENTORY_MANAGEMENT_HANDLER/FETCH_PARTY_GROUP',
);
export const fetchInventoryPartyGroupCreatorTypeName =
  fetchInventoryPartyGroupCreator(null).type;

export const fetchInventoryPartyDataCreator = createAction<any>(
  'INVENTORY_MANAGEMENT_HANDLER/FETCH_PARTY_DATA',
);
export const fetchInventoryPartyDataCreatorTypeName =
  fetchInventoryPartyDataCreator(null).type;

export const fetchInventoryPartyCategoryDataCreator = createAction<any>(
  'INVENTORY_MANAGEMENT_HANDLER/FETCH_PARTY_CATEGORY_DATA',
);
export const fetchInventoryPartyCategoryDataCreatorTypeName =
  fetchInventoryPartyCategoryDataCreator(null).type;

const inventoryManagementStateHandler = createSlice({
  name: 'INVENTORY_MANAGEMENT_HANDLER',
  initialState: inventoryManagementInitialState,
  reducers: {
    setDivisionGRP: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        divisionGRP: payload,
      };
    },
    setDivisionGRPLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        divisionGRPLoading: payload,
      };
    },
    setDivision: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        division: payload,
      };
    },
    setPartyGRP: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyGRP: payload,
      };
    },
    setPartyData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyData: payload,
      };
    },
    setPartyCategoryData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyCategoryData: payload,
      };
    },
    setIsVisibleModal: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isVisibleKeyAndValue: payload,
      };
    },
    setEditPartyDivisionData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editPartyDivisionData: payload,
      };
    },

    setPartyDivisionData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        partyDivisionMappingData: payload,
      };
    },
    setOneDataOfPartyDivision: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        oneDataOfPartyDivision: payload,
      };
    },
    setOneDataOfPartyTypeData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        oneDataOfPartyTypeData: payload,
      };
    },
  },
});

export const inventoryManagementStateActions =
  inventoryManagementStateHandler.actions;
export const inventoryManagementStateReducer =
  inventoryManagementStateHandler.reducer;
