import {useEffect, useState} from 'react';
import {Search} from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  masterManagementStateActions,
  fetchMasterPartyCategoryCreator,
} from '@app/screens/master-data-management/redux/slice';
import {useSelector, useDispatch} from 'react-redux';
import {masterManagementStateSelector} from '@app/screens/master-data-management/redux/selectors';
import {trackEvent} from '@app/utils/analytics';
import {EVENT_NAME_SEARCH_BAR} from '@app/utils/analytics/constants';
import {
  AntSwitch,
  navbarStyles,
} from '@app/components/widgets/AntSwitch/AntSwitch';

export const SearchCustomProperty = () => {
  const dispatch = useDispatch();
  const isActiveState = useSelector(
    masterManagementStateSelector.getPartyCategoryIsActiveState(),
  );
  const searchText = useSelector(
    masterManagementStateSelector.getpartyCategorySearchTextState(),
  );

  return (
    <div>
      <Toolbar sx={navbarStyles.toolbar}>
        <FormControl sx={{width: '500px'}}>
          <TextField
            value={searchText}
            // onChange={event => {
            //   trackEvent(EVENT_NAME_SEARCH_BAR, {});
            //   dispatch(
            //     masterManagementStateActions.setPartyCategorySearchText(
            //       event.target.value,
            //     ),
            //   );
            //   dispatch(fetchMasterPartyCategoryCreator(null));
            // }}

            placeholder={'Search here...'}
            sx={navbarStyles.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={navbarStyles.padding5}>
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {null}
                  {/* {adminUsersAutosuggestLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null} */}
                </>
              ),
            }}
          />
        </FormControl>

        {/* <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>InActive</Typography>
            <AntSwitch
              onChange={() => {
                dispatch(
                  masterManagementStateActions.setPartyCategoryIsActiveState(!isActiveState),
                );
                dispatch(
                  masterManagementStateActions.setPartyCategorySearchText('')
                );
                setTimeout(() => {
                dispatch(fetchMasterPartyCategoryCreator(null));
                },100);

                // dispatch(
                //   masterManagementStateActions.setAdminUsersAutosuggestData([]),
                // );
                // dispatch(masterManagementStateActions.setUserSearchText(''));
                // setTimeout(() => {
                //   dispatch(fetchAdminUsersCreator(null));
                // }, 10);
              }}
              checked={isActiveState}
              inputProps={{ 'aria-label': 'ant design' }}
            />
            <Typography>Active</Typography>
          </Stack>
        </FormGroup> */}
      </Toolbar>
    </div>
  );
};
