import Typography from '@mui/material/Typography';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {Box, Button} from '@mui/material';
import {accessGroupMasterStateActions} from '@app/screens/access-management/pages/access-group-master/redux';
import {SearchBarAccessGroup} from '@app/components/search-bar-access-group';
import {appSelector} from '@app/store/selectors';
import {trackEvent} from '@app/utils/analytics';
import {EVENT_NAME_ADD_NEW} from '@app/utils/analytics/constants';
import { masterManagementStateActions } from '@app/screens/master-data-management/redux/slice';
import { SearchPartyType } from '@app/components/search-bar-party-type';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  button: {
    borderRadius: '5px',
    width: '150px',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: 12.7,
  },
};
export default function PartyTypenavbar ()  {
  const dispatch = useDispatch();

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
    shallowEqual,
  );

  const manageAddNewPopup = () => {
    dispatch(
      masterManagementStateActions.setPartyTypeKeyAndValue({
        key: 'addNew',
        value: true,
      }),
    );
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography variant="h6" noWrap component="div">
        {navbarComponentHeading}
        </Typography>

        <Button
          variant="contained"
          // disabled={!isEditAccessAvailable}
          sx={{
            ...styles.button,
            // ...(!isEditAccessAvailable && {color: 'grey'}),
          }}
          onClick={manageAddNewPopup}
        >
          Add New
        </Button>
      </Box>

      <SearchPartyType />
    </Box>
  );
};
