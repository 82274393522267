import {API_PATH, FALSE, HTTP_OK} from '@app/common/constants';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {select} from 'redux-saga/effects';
import {masterManagementStateSelector} from './selectors';

import {
  masterManagementStateActions,
  fetchMasterPartyTypeCreatorTypeName,
  fetchMasterPartyCategoryCreatorTypeName,
  saveMasterPartyTypeCreatorTypeName,
  groupDropdownMasterPartyTypeCreatorTypeName,
  addEditDeletePartyCategoryCreatorName,
  fetchPartyFlagdetailsCreatorTypeName,
  addEditPartyFlagDetailsCreatorTypeName,
  UpdatepartyFlagDataCreatorTypeName,
  fetchPartyFlagdetailsCreator,
} from './slice';
import swal from 'sweetalert';

// Party Type
export function* fetchMaterPartyTypeDataWatcher() {
  yield takeLatest(
    fetchMasterPartyTypeCreatorTypeName,
    fetchMasterPartyTypeDataWatcherWorker,
  );
}
//  Party Type - fetch Data
export function* fetchMasterPartyTypeDataWatcherWorker(action: any): any {
  try {
    yield put(masterManagementStateActions.setMasterPartyTypeLoading(true));
    const {pageNumber} = action.payload;

    const url = API_PATH.masterManagement.fetchMasterPartyTypeData;
    const masterPartyTypeData = yield call(NetworkService.post, url, {
      ...action.payload,
      pageNumber: pageNumber + 1,
    });

    yield put(
      masterManagementStateActions.setMasterPartyType(
        masterPartyTypeData?.data,
      ),
    );
    yield put(masterManagementStateActions.setMasterPartyTypeLoading(false));
  } catch (error) {
    yield put(masterManagementStateActions.setMasterPartyTypeLoading(false));
    ToasterService.showToaster('Something went wrong !', ToastType.ERROR);
  }
}
// Party Type - Save and Update/Edit Data
export function* saveMasterPartyTypeWatcher() {
  yield takeLatest(
    saveMasterPartyTypeCreatorTypeName,
    saveMasterPartyTypeWatcherWorker,
  );
}

export function* saveMasterPartyTypeWatcherWorker(action: any): any {
  try {
    yield put(masterManagementStateActions.setMasterPartyTypeLoading(true));

    const url = `${API_PATH.masterManagement.saveMasterPartyTypeAPI}`;

    const response = yield call(NetworkService.put, url, action.payload);

    if (response) {
      yield put(masterManagementStateActions.setMasterPartyTypeLoading(false));

      if (response?.status === 200) {
        if (action.payload.isDeleted == true) {
          swal('Deleted!', 'Record has been deleted!', 'success');
        }
        if (action.payload.isDeleted == false) {
          ToasterService.showToaster(
            `${
              action.payload.id != 0
                ? 'Data updated successfully'
                : 'Data saved successfully'
            }`,
            ToastType.SUCCESS,
          );
        }
      } else {
        if (action.payload.isDeleted == true) {
          swal('Oops!', 'The record has not been deleted!', 'error');
        }
        if (action.payload.isDeleted == false) {
          ToasterService.showToaster(
            `${
              action.payload.id != 0
                ? 'Data is not update successfully'
                : 'Data is not save successfully'
            }`,
            ToastType.ERROR,
          );
        }
      }
    }
  } catch (error) {
    ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
  }
}
// Party Type - Group Dropdown Master Data
export function* groupDropdownMasterPartyTypeWatcher() {
  yield takeLatest(
    groupDropdownMasterPartyTypeCreatorTypeName,
    groupDropdownMasterPartyTypeWatcherWorker,
  );
}

export function* groupDropdownMasterPartyTypeWatcherWorker(): any {
  try {
    yield put(masterManagementStateActions.setMasterPartyTypeLoading(true));

    const url = `${API_PATH.masterManagement.groupDropdownMasterPartyTypeAPI}`;

    const response = yield call(NetworkService.get, url, {}, {});

    if (response) {
      yield put(masterManagementStateActions.setMasterPartyTypeLoading(false));
      if (response?.status === 200) {
        yield put(
          masterManagementStateActions.setGroupDropdownMasterPartyTypeData(
            response?.data,
          ),
        );
      } else {
        ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
      }
    }
  } catch (error) {
    ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
  }
}

// Part Category

export function* fetchMaterPartyCategoryDataWatcher() {
  yield takeLatest(
    fetchMasterPartyCategoryCreatorTypeName,
    fetchMasterPartyCategoryDataWatcherWorker,
  );
}
// Part Category - fetch Data
export function* fetchMasterPartyCategoryDataWatcherWorker(action: any): any {
  try {
    yield put(masterManagementStateActions.setMasterPartyCategoryLoding(true));
    const {pageNumber} = action.payload;

    const url = API_PATH.masterManagement.fetchMasterPartyCategoryData;
    const masterPartyCategoryeData = yield call(NetworkService.post, url, {
      ...action.payload,
      pageNumber: pageNumber + 1,
    });

    yield put(
      masterManagementStateActions.setMasterPartyCategory(
        masterPartyCategoryeData?.data,
      ),
    );
    yield put(masterManagementStateActions.setMasterPartyCategoryLoding(false));
  } catch (error) {
    yield put(masterManagementStateActions.setMasterPartyCategoryLoding(false));
    ToasterService.showToaster('Something went wrong !', ToastType.ERROR);
  }
}
//  Part Category - Save Data
export function* masterAddEditDeletePartyCategoryDataWatcher() {
  yield takeLatest(
    addEditDeletePartyCategoryCreatorName,
    masterAddEditDeletePartyCategoryDataWatcherWorker,
  );
}
//  Part Category - Save Data
export function* masterAddEditDeletePartyCategoryDataWatcherWorker(
  action: any,
): any {
  try {
    yield put(masterManagementStateActions.setMasterPartyCategoryLoding(true));
    const url = `${API_PATH.masterManagement.saveMasterPartTypeCategoryData}`;
    const addNewPopupVisible = yield select(
      masterManagementStateSelector.getPartyCategoryKeyAndValue(),
    );
    const getSearchText = yield select(
      masterManagementStateSelector.getpartyCategorySearchTextState(),
    );
    const isActiveState = yield select(
      masterManagementStateSelector.getPartyCategoryIsActiveState(),
    );
    const page = yield select(
      masterManagementStateSelector.getPartyCategoryPage(),
    );
    const pageSize = yield select(
      masterManagementStateSelector.getPartyCategoryPageSize(),
    );
    const savemasterPartyCategoryeData = yield call(
      NetworkService.put,
      url,
      action.payload,
    );
    yield put({
      type: fetchMasterPartyCategoryCreatorTypeName,
      payload: {
        isActive: isActiveState,
        searchText: getSearchText,
        pageNumber: page,
        rowPerPage: pageSize,
      },
    });
    if (savemasterPartyCategoryeData.status == HTTP_OK) {
      yield put(
        masterManagementStateActions.setMasterPartyCategorySaveError(false),
      );
      yield put(
        masterManagementStateActions.setPartyCategoryKeyAndValue({
          ...addNewPopupVisible,
          value: false,
        }),
      );
      if (action.payload.isDeleted == true) {
        swal('Deleted!', 'Record has been deleted!', 'success');
      }
      if (action.payload.isDeleted == false) {
        // if(action.payload.isActive){
        //  return ToasterService.showToaster(`Data is Active`, ToastType.SUCCESS);
        // }
        // else if(!action.payload.isActive){
        // return  ToasterService.showToaster(`Data is In-Active`, ToastType.SUCCESS);
        // }
        ToasterService.showToaster(
          `${
            action.payload.id != 0
              ? 'Data updated successfully'
              : 'Data saved successfully'
          }`,
          ToastType.SUCCESS,
        );
      }
    } else {
      if (savemasterPartyCategoryeData?.data?.description) {
        yield put(
          masterManagementStateActions.setMasterPartyCategorySaveError(true),
        );
        ToasterService.showToaster(
          'Please add unique color code and category name',
          ToastType.ERROR,
        );
      } else if (action.payload.isDeleted == true) {
        swal('Oops!', 'The record has not been deleted!', 'error');
      } else if (action.payload.isDeleted == false) {
        ToasterService.showToaster(
          `${
            action.payload.id != 0
              ? 'Data is not update successfully'
              : 'Data is not save successfully'
          }`,
          ToastType.ERROR,
        );
      }
    }
  } catch (error) {
    ToasterService.showToaster('Something went wrong !', ToastType.ERROR);
    // yield put(masterManagementStateActions.setMasterPartyCategoryLoding(false));
  }
}
//party Flag
export function* addEditPartyFlagDetailsWatcher() {
  yield takeLatest(
    addEditPartyFlagDetailsCreatorTypeName,
    addEditPartyFlagDetailsWorker,
  );
}
export function* addEditPartyFlagDetailsWorker(action: any): any {
  try {
    const url = `${API_PATH.masterManagement.savePartyFlagDetails}`;
    const getSearchText = yield select(
      masterManagementStateSelector.getpartyFlagSearchTextState(),
    );
    const addNewPopupVisible = yield select(
      masterManagementStateSelector.getPartyFlagKeyAndValue());
    const isActiveState = yield select(
      masterManagementStateSelector.getPartyFlagIsActiveState(),
    );
    const page = yield select(masterManagementStateSelector.getPartyFlagPage());
    const pageSize = yield select(
      masterManagementStateSelector.getPartyFlagPageSize(),
    );
    const partyflagdetails = yield call(
      NetworkService.put,
      url,
      action.payload,
    );

    if (partyflagdetails.status == 200) {
      yield put(masterManagementStateActions.setPartyFlagError(false));
      yield put(masterManagementStateActions.setPartyFlagKeyAndValue({
        ...addNewPopupVisible,
        value: false
      }));
     
      yield put(
        masterManagementStateActions.setAddEditpartyFlagDetails(
          partyflagdetails?.data,
        ),
      );
      yield put({
        type: fetchPartyFlagdetailsCreatorTypeName,
        payload: {
          isActive: isActiveState,
          searchText: getSearchText,
          pageNumber: page,
          rowPerPage: pageSize,
        },
      });
     ToasterService.showToaster(
          `${
            action?.payload?.id != 0
              ? 'Party Flag details updated successfully'
              : 'Party Flag details saved successfully'
          }`,
          ToastType.SUCCESS,
        );
      }  
      else {
        if (partyflagdetails?.data?.description) {
          yield put(masterManagementStateActions.setPartyFlagError(true));
          ToasterService.showToaster(
            'Please add a unique color code and Party flag name',
            ToastType.ERROR
          );
        } 
        else 
          if (action.payload.isDeleted == false) {
            ToasterService.showToaster(
              `${
                action?.payload?.id != 0
                  ? 'Data is not updated successfully'
                  : 'Data is not saved successfully'
              }`,
              ToastType.ERROR,
            );
          }
        
      } 
    }    
  catch (error) {
    ToasterService.showToaster('Something went wrong !', ToastType.ERROR);
  }
}

export function* fetchPartyFlagdetailsWatcher() {
  yield takeLatest(
    fetchPartyFlagdetailsCreatorTypeName,
    fetchPartyFlagdetailsWorker,
  );
}
export function* fetchPartyFlagdetailsWorker(action: any): any {
  try {
    yield put(masterManagementStateActions.setpartyFlagDetailsLoading(true));
    const {pageNumber} = action.payload;
    const url = `${API_PATH.masterManagement.fetchAllPartyFlagdetails}`;
    const partyflagdetails = yield call(NetworkService.post, url, {
      ...action.payload,
      pageNumber: pageNumber + 1,
    });

    if (partyflagdetails.status == 200) {
      yield put(
        masterManagementStateActions.setpartyFlagDetails(
          partyflagdetails?.data?.partyFlags,
        ),
      );
      yield put(
        masterManagementStateActions.setTotalCount(
          partyflagdetails?.data?.totalCount,
        ),
      );
    }
  } catch (error) {
    yield put(masterManagementStateActions.setpartyFlagDetailsLoading(false));
    ToasterService.showToaster('Something went wrong !', ToastType.ERROR);
  } finally {
    yield put(masterManagementStateActions.setpartyFlagDetailsLoading(false));
  }
}

//View party details
export function* UpdatepartyFlagDataWatcher() {
  yield takeLatest(
    UpdatepartyFlagDataCreatorTypeName,
    UpdatepartyFlagDataWorker,
  );
}
export function* UpdatepartyFlagDataWorker(action: any): any {
  try {
    const {id} = action.payload;
    const url = `${API_PATH.masterManagement.fetchPartyFlagDetails}/${id}`;

    const partyflagdetails = yield call(NetworkService.get, url, {}, {});

    if (partyflagdetails.status == 200) {
      yield put(
        masterManagementStateActions.setUpdatepartyFlagData(
          partyflagdetails?.data,
        ),
      );
    }
  } catch (error) {
    yield put(masterManagementStateActions.setpartyFlagDetailsLoading(false));
    ToasterService.showToaster('Something went wrong !', ToastType.ERROR);
  }
}
