import {createSelector} from '@reduxjs/toolkit';

export const masterManagementStateSelector = {
  // Party Type
  getMasterPartyTypeData: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.masterPartyType],
      masterPartyType => masterPartyType,
    );
  },

  getMasterPartyTypeDataLoading: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.masterPartyTypeLoading],
      masterPartyTypeLoading => masterPartyTypeLoading,
    );
  },
  getGroupDropdownMasterPartyTypeData: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.groupDropdownMasterPartyTypeData],
      groupDropdownMasterPartyTypeData => groupDropdownMasterPartyTypeData,
    );
  },
  getPartyTypeIsActiveState: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyTypeIsActiveState],
      partyTypeIsActiveState => partyTypeIsActiveState,
    );
  },
  getPartyTypeKeyAndValue: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyTypeKeyAndValue],
      partyTypeKeyAndValue => partyTypeKeyAndValue,
    );
  },
  getEditPartyTypeData: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.editPartyTypeData],
      editPartyTypeData => editPartyTypeData,
    );
  },
  getPartyTypeColorCode: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.getPartyTypeColorCode],
      getPartyTypeColorCode => getPartyTypeColorCode,
    );
  },
  getpartyTypeSearchTextState: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyTypeSearchText],
      partyTypeSearchText => partyTypeSearchText,
    );
  },

  getPartyTypeMasterPage: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyTypeMasterPage],
      partyTypeMasterPage => partyTypeMasterPage,
    );
  },

  getPartyTypeMasterPageSize: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyTypeMasterPageSize],
      partyTypeMasterPageSize => partyTypeMasterPageSize,
    );
  },

  // Party Category
  getMasterPartyCategoryData: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.masterPartyCategoryData],
      masterPartyCategoryData => masterPartyCategoryData,
    );
  },

  getMasterPartyCategoryLoading: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.masterPartCategoryLoading],
      masterPartCategoryLoading => masterPartCategoryLoading,
    );
  },
  getPartyCategoryIsActiveState: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyCategoryIsActiveState],
      partyCategoryIsActiveState => partyCategoryIsActiveState,
    );
  },
  getpartyCategorySearchTextState: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyCategorySearchText],
      partyCategorySearchText => partyCategorySearchText,
    );
  },
  getPartyCategoryKeyAndValue: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyCategoryKeyAndValue],
      partyCategoryKeyAndValue => partyCategoryKeyAndValue,
    );
  },
  getEditPartyCategoryData: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.editPartyCategoryData],
      editPartyCategoryData => editPartyCategoryData,
    );
  },
  getPartyCategoryColorCode: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyCategoryColorCode],
      partyCategoryColorCode => partyCategoryColorCode,
    );
  },
  getPartyCategoryPage: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyCategoryPage],
      partyCategoryPage => partyCategoryPage,
    );
  },
  getPartyCategoryPageSize: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyCategoryPageSize],
      partyCategoryPageSize => partyCategoryPageSize,
    );
  },
  getPartyCategorySaveError: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyCategorySaveError],
      partyCategorySaveError => partyCategorySaveError,
    );
  },
  getSavePartyCategoryData: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.savePartyCategoryData],
      savePartyCategoryData => savePartyCategoryData,
    );
  },
  // party Flag
  getPartyFlagDetailsLoading: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.PartyFlagDetailsLoading],
      PartyFlagDetailsLoading => PartyFlagDetailsLoading,
    );
  },
  getpartyFlagDetails: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyFlagDetails],
      partyFlagDetails =>partyFlagDetails,
    )
  },
  getAddEditpartyFlagDetails: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.addEditPartyDetails],
      addEditPartyDetails =>addEditPartyDetails,
    )
  },
  getTotalCount: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.totalCount],
      totalCount => totalCount,
    );
  },
  getUpdatepartyFlagData: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.updatepartyFlagData],
      updatepartyFlagData =>updatepartyFlagData,
    )
  },
  getPartyFlagPage: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyFlagPage],
      partyFlagPage => partyFlagPage,
    );
  },
  getPartyFlagPageSize: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyFlagPageSize],
      partyFlagPageSize => partyFlagPageSize,
    );
  },
  getPartyFlagIsActiveState: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyFlagIsActiveState],
      partyFlagIsActiveState => partyFlagIsActiveState,
    );
  },
  getpartyFlagSearchTextState: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyFlagSearchText],
      partyFlagSearchText => partyFlagSearchText,
    );
  },
  
  getPartyFlagKeyAndValue: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyFlagKeyAndValue],
      partyFlagKeyAndValue => partyFlagKeyAndValue,
    );
  },
  getEditPartyFlagData: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.editPartyFlagData],
      editPartyFlagData => editPartyFlagData,
    );
  },
  getPartyFlagColorCode: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyFlagColorCode],
      partyFlagColorCode => partyFlagColorCode,
    );
  },
  getPartyFlagError: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyFlagError],
      partyFlagError => partyFlagError,
    );
  },
  getpartyFlagSavedData: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyFlagSavedData],
      partyFlagSavedData => partyFlagSavedData,
    );
  },


  //  Party Type Group
  getPartyGroupKeyAndValue: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.partyGroupKeyAndValue],
      partyGroupKeyAndValue => partyGroupKeyAndValue,
    );
  },

  // Division Group

  getDivisonGroupKeyAndValue: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.divisionGroupKeyAndValue],
      divisionGroupKeyAndValue => divisionGroupKeyAndValue,
    );
  },

  // custom property

  getCustomPropertyKeyAndValue: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.customPropertyKeyAndValue],
      customPropertyKeyAndValue => customPropertyKeyAndValue,
    );
  },
  getEditCustomPropertyData: () => {
    return createSelector(
      [(state: any) => state.MasterDataManagement.editCustomPropertyData],
      editCustomPropertyData => editCustomPropertyData,
    );
  },
};
